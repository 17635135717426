import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable()
export class ScreensizeService {

  private _isDesktop$ = new BehaviorSubject<boolean>(
    window.innerWidth < 768 ? false : true
  );

  constructor() {}

  onResize(size: number) {
    if (size < 768) {
      this._isDesktop$.next(false);
    } else {
      this._isDesktop$.next(true);
    }
  }

  isDesktopView(): Observable<boolean> {
    return this._isDesktop$.asObservable().pipe(distinctUntilChanged());
  }
}
